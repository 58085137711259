type NotificationType = 'success' | 'error';
type Notification = {
  message: string;
  type: NotificationType;
  icon?: string;
};

export const useNotification = () => {
  const iconName = useState<string>('icon', () => '');
  const info = useState<string>('info', () => '');
  const infoType = useState<NotificationType>('infoType', () => 'success');
  const showNotification = useState<boolean>('showNotification', () => false);
  const timeoutID = useState<number | NodeJS.Timeout | undefined>('timeoutID', () => undefined);

  const resetNotification = () => {
    iconName.value = '';
    info.value = '';
    infoType.value = 'success';
    showNotification.value = false;
  };

  const setNotification = ({ message, type, icon }: Notification) => {
    if (timeoutID.value) return;
    resetNotification();
    info.value = message;
    if (['success', 'error'].includes(type)) {
      infoType.value = type;
    }
    if (typeof icon === 'string') {
      iconName.value = icon;
    }
    showNotification.value = true;
    timeoutID.value = setTimeout(() => {
      resetNotification();
      if (typeof timeoutID.value === 'number') {
        clearTimeout(timeoutID.value);
      }
      timeoutID.value = undefined;
    }, 10000);
  };

  return {
    iconName,
    info,
    infoType,
    resetNotification,
    setNotification,
    showNotification
  };
};
